import { ExtendedProfile, UserInfo } from './dataTypes';

export const initialExtendedProfile: ExtendedProfile = {
    assignedRoles: [],
    username: '',
    name: '',
    attributes: {
        companyId: null,
        companyType: null,
    },
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: null,
};

export const initialUserInfo: UserInfo = {
    assignedRoles: [],
    avatar: '',
    companyId: null,
    id: null,
    companyName: null,
    name: '',
    email: '',
};

export const getAvatar = (gender: string) => {
    return gender || '';
};

export const getUserInfo = (extendedProfile: ExtendedProfile, avatar = ''): UserInfo => {
    const {
        assignedRoles = [],
        email,
        name,
        attributes,
    } = extendedProfile;

    const companyId = Number(attributes?.companyId[0]) || null;

    return {
        avatar,
        assignedRoles,
        companyId,
        email,
        name,
    };
};
